import React from 'react';

function Protected(){
    return (
        <div>
            <h1>Protected page</h1>
        </div>
    )
}

export default Protected;